import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import ReactHtmlParser from 'react-html-parser'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import Helmet from 'react-helmet'

import Layout from '../components/layout'

const DownloadPage = ({ data: { download } }) => {
  const [completed] = useState(typeof window !== 'undefined' && window.location.search.includes('completed'))

  const Form = () => (
    <>
      <Helmet script={[
        {
          type: 'text/javascript',
          innerHTML: `var form_id = '${download.formId}'`
        },
        { src: '//app8.campus-site.com/assets/application/js/formgen.js', type: 'text/javascript' }
      ]} />
      {<div id={`form_${download.formId}`} />}
    </>
  )
  const Download = () => (
    <div className="hero">
      <div className="hero-body">
        <div className="container">
          <p>Thank you. Please download your free download below:</p>
          <br/>
          <a className="button is-primary" href={`${download.download.url}?dl${download.download.title ? `=${download.download.title}.pdf` : ''}`} target="_blank" rel="noopener noreferrer" download>
            Download
          </a>
        </div>
      </div>
    </div>
  )
  return (
    <Layout>
      <HelmetDatoCms seo={download.seoMetaTags} />
      <section
        className="hero is-medium is-page-hero"
        style={{
          backgroundImage: `url(${download.featuredImage.fixed.src})`
        }}
      >
        <div className="hero-body">
          <div className="container">
            <h1 className="title has-text-white has-text-weight-normal is-size-2">
              {download.title}
            </h1>
          </div>
        </div>
      </section>
      <div className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-two-thirds">
              <div className="content blog-content">
                {ReactHtmlParser(download.contentNode.childMarkdownRemark.html)}
              </div>
            </div>
            <div className="column">
              {completed ? <Download /> : <Form />}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

DownloadPage.propTypes = {
  data: PropTypes.object.isRequired
}

export default DownloadPage

export const query = graphql`
  query Download ($slug: String!) {
    download: datoCmsDownload(slug: { eq: $slug }) {
      id
      title
      slug
      featuredImage {
        fixed(
          width: 1920
          height: 600
          imgixParams: {
            h: "800"
            auto: "compress"
            fm: "pjpg"
            fit: "crop"
            crop: "focalpoint"
            bri: -10
          }
        ) {
          src
        }
      }
      formId
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      download: resource {
        url
        title
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`
